export function getInitialNulledData() {
    return {
        id: null,
        USER: null,
        date: null,
        Variables: null,
        intro_applicant_firstname: null,
        intro_applicant_lastname: null,
        intro_project_title: null,
        intro_background: null,
        intro_objectives: null,
        intro_overlap: null,
        intro_novelty: null,
        methods_outcome: null,
        methods_description: null,
        methods_confounder: null,
        methods_design: null,
        methods_design_prosp: null,
        methods_design_other: null,
        methods_sample_size: null,
        methods_sample_size_exception: null,
        methods_power_calc: null,
        methods_setting_baseline: null,
        methods_setting_yearly: null,
        methods_setting_yearly_year: null,
        methods_setting_mri: null,
        methods_setting_mri_cardio: null,
        methods_setting_mri_neuro: null,
        methods_setting_other: null,
        methods_setting_other_statement: null,
        methods_timeline: null,
        methods_bias_limitations: null,
        analysis_exclusion: null,
        analysis_inclusion: null,
        analysis_subgroups: null,
        analysis_missing_variables: null,
        analysis_statistical_methods: null,
        analysis_exam_methods: null,
        analysis_missing_data: null,
        analysis_loss_fu: null,
        analysis_sensitivity_analyses: null,
        analysis_presentation_type: null,
        need_bio: null,
        biomaterial_which: null,
        biomaterial_number: null,
        biomaterial_volume: null,
        biomaterial_marker: null,
        biomaterial_procedure: null,
        biomaterial_multiple: null,
        biomaterial_where: null,
        biomaterial_financial: null,
        biomaterial_shipment: null,
        biomaterial_transp_company: null,
        biomaterial_return: null,
        biomaterial_leftover: null,
        other_ip_rights: null,
        other_partners: null,
        other_financial: null,
        other_manuscript: null,
        final: null,
        status: null,
    };
}
export const ProposalKeys = [
    "id",
    "USER",
    "date",
    "Variables",
    "intro_applicant_firstname",
    "intro_applicant_lastname",
    "intro_project_title",
    "intro_background",
    "intro_objectives",
    "intro_overlap",
    "intro_novelty",
    "methods_outcome",
    "methods_description",
    "methods_confounder",
    "methods_design",
    "methods_design_prosp",
    "methods_design_other",
    "methods_sample_size",
    "methods_sample_size_exception",
    "methods_power_calc",
    "methods_setting_baseline",
    "methods_setting_yearly",
    "methods_setting_yearly_year",
    "methods_setting_mri",
    "methods_setting_mri_cardio",
    "methods_setting_mri_neuro",
    "methods_setting_other",
    "methods_setting_other_statement",
    "methods_timeline",
    "methods_bias_limitations",
    "analysis_exclusion",
    "analysis_inclusion",
    "analysis_subgroups",
    "analysis_missing_variables",
    "analysis_statistical_methods",
    "analysis_exam_methods",
    "analysis_missing_data",
    "analysis_loss_fu",
    "analysis_sensitivity_analyses",
    "analysis_presentation_type",
    "need_bio",
    "biomaterial_which",
    "biomaterial_number",
    "biomaterial_volume",
    "biomaterial_marker",
    "biomaterial_procedure",
    "biomaterial_multiple",
    "biomaterial_where",
    "biomaterial_financial",
    "biomaterial_shipment",
    "biomaterial_transp_company",
    "biomaterial_return",
    "biomaterial_leftover",
    "other_ip_rights",
    "other_partners",
    "other_financial",
    "other_manuscript",
    "final",
    "status",
];
;
