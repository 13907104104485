import { GetResponseShapeError, fetch_json_endpoint, isResponse, isResponseStatus, onErrorResponse } from "../RequestHelpers";
import { isString } from "../helper_functions";
import { test_jwt, test_requestor_mail } from "../test_config";
import { Proposal_base } from "./Proposal_base";
export class Proposal extends Proposal_base {
    db_response;
    constructor() {
        super();
        this.db_response = null;
    }
    getResponseStatus() {
        if (isResponse(this.db_response) && isResponseStatus(this.db_response?.status)) {
            return this.db_response.status;
        }
        return "no response status available";
    }
    getResponseMessage() {
        if (isResponse(this.db_response) && isString(this.db_response.message)) {
            return this.db_response.message;
        }
        return "no response message available";
    }
    static async db_get_all_proposals(user_mail, auth_token) {
        const body = {
            mail: user_mail,
            jwt: auth_token
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/get_all_proposals.php", body);
        try {
            onErrorResponse(response);
        }
        catch (error) {
            throw error;
        }
        if (isResponse(response) && response.hasOwnProperty('request_details') && response.request_details.hasOwnProperty('proposals')) {
            const proposals = response.request_details.proposals.map((collection) => {
                let proposal = new Proposal();
                proposal.setProposalData(collection);
                return proposal;
            });
            return proposals;
        }
        return [];
    }
    async db_create_proposal(requestor_mail, auth_token) {
        this.setProposalAttribute('status', 'propoasl issued');
        const request_body = {
            mail: requestor_mail,
            jwt: auth_token,
            proposal_data: this.proposal_data
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/create_proposal.php", request_body);
        this.db_response = response;
        try {
            onErrorResponse(response);
            if (!(isResponse(response) && response.hasOwnProperty('request_details') && response.request_details.hasOwnProperty('proposal_id'))) {
                throw GetResponseShapeError(response);
            }
        }
        catch (error) {
            throw error;
        }
        this.proposal_data.id = response.request_details.proposal_id;
        return this;
    }
    async db_proposal_nachtragen(requestor_mail, auth_token) {
        requestor_mail = test_requestor_mail;
        auth_token = test_jwt;
        const request_body = {
            mail: requestor_mail,
            jwt: auth_token,
            proposal_data: this.proposal_data
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/proposal_nachtragen.php", request_body);
        this.db_response = response;
        try {
            if (!(isResponse(response) && response.hasOwnProperty('request_details') && response.request_details.hasOwnProperty('proposal_id'))) {
                throw GetResponseShapeError(response);
            }
            onErrorResponse(response);
        }
        catch (error) {
            throw error;
        }
        this.proposal_data.id = response.request_details.proposal_id;
        return this;
    }
    async db_set_status(requestor_mail, auth_token) {
        requestor_mail = test_requestor_mail;
        auth_token = test_jwt;
        this.validate_id();
        const body = {
            mail: requestor_mail,
            jwt: auth_token,
            proposal_id: this.proposal_data.id,
            proposal_status: this.proposal_data.status
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/set_proposal_status.php", body);
        this.db_response = response;
        try {
            onErrorResponse(response);
        }
        catch (error) {
            throw error;
        }
        return this;
    }
    async db_update_proposal(requestor_mail, auth_token) {
        this.validate_id();
        const body = {
            mail: requestor_mail,
            jwt: auth_token,
            proposal_data: this.proposal_data
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/update_proposal.php", body);
        this.db_response = response;
        try {
            onErrorResponse(response);
        }
        catch (error) {
            throw error;
        }
        return this;
    }
    async db_get_proposal_data(requestor_mail, auth_token) {
        requestor_mail = test_requestor_mail;
        auth_token = test_jwt;
        this.validate_id();
        const body = {
            mail: requestor_mail,
            jwt: auth_token,
            id: this.proposal_data.id
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/get_proposal_for_id.php", body);
        try {
            onErrorResponse(response);
            if (!(isResponse(response) && response.hasOwnProperty('request_details') && response.request_details.hasOwnProperty('proposal_data'))) {
                throw GetResponseShapeError(response);
            }
        }
        catch (error) {
            throw error;
        }
        this.setProposalData(response.request_details.proposal_data);
        return this;
    }
    static async db_get_proposals_for_user(user_mail, auth_token) {
        const body = {
            mail: user_mail,
            jwt: auth_token,
        };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/get_proposals_of_user.php", body);
        try {
            onErrorResponse(response);
            if (!(isResponse(response) && response.hasOwnProperty('request_details') && response.request_details.hasOwnProperty('data'))) {
                throw GetResponseShapeError(response);
            }
        }
        catch (error) {
            throw error;
        }
        const proposals = response.request_details.data.map((collection) => {
            let proposal = new Proposal();
            proposal.setProposalData(collection);
            return proposal;
        });
        return proposals;
    }
}
