import { isOneOf } from "typescript_helpers";
import { Collection, getAttribute, NamelessGrouping, SingleChoice, Text } from "./structuring_elements";

const optionEvaluatorBinaryYesNo = 
  (proposal, content_key, name) => {
    const attribute_value = getAttribute(proposal, content_key);
    console.log(`biomaterial option name ${name}`);
    if(name === 'Yes'){
      let result = !isOneOf(attribute_value, ['No', 'no', 'false', false, ""]);
      console.log(`Yes with value ${attribute_value} returns ${result}`);
      return result;
    }
    if(name === 'No'){
      console.log(attribute_value);
      let result = isOneOf(attribute_value, ['No', 'no', 'false', false, "", "null", null]);
      console.log(`No with value ${attribute_value} returns ${result}`);
      //return result;
      return true;
    }
    throw new Error("options skipped")
    return false;
  }


const conditional_section_bio_shipment = new NamelessGrouping(
  [
    new Text('To Whom Will They Be Shipped?', 'biomaterial_shipment'),
    new Text('Transport Company', 'biomaterial_transp_company'),
    new SingleChoice(
      'Can The Materials Be Returned After Completion Of Measurements?',
      'biomaterial_return',
      [
        {
          optionName: 'Yes',
          optionConditionalContent: false,
        },
        {
          optionName: 'No',
          optionConditionalContent: new Text(
            'Reasoning For Materials Not Being Returnable',
            'biomaterial_transp_company'
          )
        }
      ],
      optionEvaluatorBinaryYesNo
    )
  ]
)

const conditional_section_bio_needed = new NamelessGrouping(
  [
    new Text('Which Biomaterials Will Be Needed?', 'biomaterial_which'),
    new Text('Total Number / Subject Specification', 'biomaterial_number'),
    new Text('Volume?', 'biomaterial_volume'),
    new Text('Which Marker(s) Should Be Measured?', 'biomaterial_marker'),
    new Text('Which Procedure Is Needed To Measure The Marker?', 'biomaterial_procedure'),
    new SingleChoice(
      'Are There Multiple Determinations Per Propband Necessary?', 
      'biomaterial_multiple',
      [
        {
          optionName: 'Yes',
          optionConditionalContent: new Text('Reasoning', 'biomaterial_multiple')
        },
        {
          optionName: 'No',
          optionConditionalContent: false
        }
      ],
      optionEvaluatorBinaryYesNo
    ),
    new Text('Financing', 'biomaterial_financial'),
    new Text('Where Do The Measurements Take Place?', 'biomaterial_where'),
    new SingleChoice(
      'Will The Biomaterials Be Shipped?',
      'biomaterial_shipment',
      [
        {
          optionName: 'Yes',
          optionConditionalContent: conditional_section_bio_shipment
        },
        {
          optionName: 'No',
          optionConditionalContent: false
        }
      ],
      optionEvaluatorBinaryYesNo
    )
  ]
)

export const collection_biomaterials = new Collection(
  'Biomaterials',
  [
    new SingleChoice(
      'Do You Need Biomaterials?',
      'need_bio',
      [
        {
          optionName: 'Yes',
          optionConditionalContent: conditional_section_bio_needed
        },
        {
          optionName: 'No',
          optionConditionalContent: false
        }
      ],
      optionEvaluatorBinaryYesNo
    )
  ],
  )

