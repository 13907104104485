import { isNonNullObject, isString } from "../helper_functions";
function isActualCurrentNodeEntry(elem) {
    return (isCurrentNodeEntry(elem) &&
        isString(elem.current));
}
function isCurrentNodeEntry(elem) {
    return (isNonNullObject(elem) &&
        elem.hasOwnProperty('current') &&
        (isString(elem.current) || elem.current === null));
}
export class CurrentNodeManager {
    currentNodes;
    lastTouchedElements;
    constructor() {
        this.currentNodes = {};
        this.lastTouchedElements = {
            proposal_id: "",
            kind: "",
            node_id: ""
        };
    }
    containsProposal(proposal_id) {
        return this.currentNodes.hasOwnProperty(proposal_id);
    }
    containsKindForProposal(proposal_id, kind) {
        return (this.containsProposal(proposal_id) &&
            this.currentNodes[proposal_id].hasOwnProperty(kind));
    }
    currentNodeSetForProposalAndKind(proposal_id, kind) {
        return (this.containsKindForProposal(proposal_id, kind) &&
            isCurrentNodeEntry(this.currentNodes[proposal_id][kind]));
    }
    getCurrentNodeForProposalAndKind(proposal_id, kind) {
        return (this.currentNodeSetForProposalAndKind(proposal_id, kind) &&
            isActualCurrentNodeEntry(this.currentNodes[proposal_id][kind]) &&
            this.currentNodes[proposal_id][kind].current);
    }
    getLastTouchedElements() {
        return this.lastTouchedElements;
    }
    setCurrentNode(proposal_id, kind, node_id) {
        if (!this.containsProposal(proposal_id)) {
            this.currentNodes[proposal_id] = {};
        }
        this.currentNodes[proposal_id][kind] = { 'current': node_id };
        this.lastTouchedElements = { proposal_id: proposal_id, kind: kind, node_id: node_id };
        return this;
    }
}
