import { isOneOf } from "../../helper_functions";
export const methods_design_followUp_acceptedValues = [
    'Prospective with Follow-up',
    'prospective with Follow-up',
    'Prospective with FollowUp',
    'Prospective Follow-up',
    'prospective Follow-up',
    'Prospective FollowUp',
    'Prospective with Follow-Up',
    'prospective with Follow-Up',
    'Prospective with Followup',
    'Prospective Follow-Up',
    'prospective Follow-Up',
    'Prospective Followup',
];
export const methods_design_other_acceptedValues = [
    'Other', 'other', 'Other (please elaborate)', 'other (please elaborate)'
];
export function isMethodsDesign(elem) {
    return (isOneOf(elem, methods_design_other_acceptedValues) ||
        isOneOf(elem, methods_design_followUp_acceptedValues));
}
export function isMethodsDesignFollowUp(elm) {
    return isOneOf(elm, methods_design_followUp_acceptedValues);
}
export function isMethodsDesignOther(elm) {
    return isOneOf(elm, methods_design_other_acceptedValues);
}
