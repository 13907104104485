import { isOneOf } from "typescript_helpers";
import { Collection, getAttribute, MultiChoice, SingleChoice, Text } from "./structuring_elements";



export const single_choice_hchs_cohort = new SingleChoice(
  'HCHS Cohort',
  'methods_sample_size',
  [
    {
      optionName: '10.000',
      optionConditionalContent: false
    },
    {
      optionName: '17.000',
      optionConditionalContent: false
    },
    {
      optionName: 'post-COVID',
      optionConditionalContent: false
    },
    {
      optionName: 'Exceptions',
      optionConditionalContent: new Text(
        'Indicate Further Information',
        'methods_sample_size_exception') 
    },
  ],
  (proposal, content_key, name) => {
    const attribute_value = getAttribute(proposal, content_key);
    if (name === '10.000') {
      return isOneOf(attribute_value, ['10.000', '10000']);
    }
    if (name === '17.000') {
      return isOneOf(attribute_value, ['17.000', '17000']);
    }
    if (name === 'post-COVID') {
      return isOneOf(attribute_value, ['post-COVID', 'post covid', 'post-Covid', 'post-covid']);
    }
    if (name === 'Exceptions') {
      return isOneOf(attribute_value, ['Exceptions', 'Exception', 'exceptions', 'exception']);
    }
    return false;
  }
)

export const conditional_section_mri = new MultiChoice(
  'Select kind of MRI examinition',
  [
    {
      optionName: 'cardio',
      content_key: 'methods_setting_mri_cardio',
      conditional_section: false
    },
    {
      optionName: 'neuro',
      content_key: 'methods_setting_mri_neuro',
      conditional_section: false
    }
  ]
)



const multi_choice_setting = new MultiChoice(
      'Setting',
      [
        {
          optionName: 'Baseline',
          content_key: 'methods_setting_baseline',
          conditional_section: false
        },
        {
          optionName: 'Yearly Questionaire',
          content_key: 'methods_setting_yearly',
          conditional_section: new Text(
            'Indicate Year',
            'methods_setting_yearly_year'
          )
        },
        {
          optionName: 'MRI',
          content_key: 'methods_setting_mri',
          conditional_section: conditional_section_mri
        },
        {
          optionName: 'Others',
          content_key: 'methods_setting_other',
          conditional_section: new Text(
            'Statement',
            'methods_setting_other_statement'
          )
        }
      ]
)

export const collection_methodology = new Collection(
  'Methodology',
  [
    multi_choice_setting,
    single_choice_hchs_cohort,
    new Text('POWER CALCULATION', 'methods_power_calc'),
    new Text('Bias and Limitation', 'methods_bias_limitations'),
    new Text('Estimated Timeline', 'methods_timeline')
  ]
)
