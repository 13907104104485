import { eval_true_values } from "../custom_types";
import { isOneOf, objectKeysValid, stringify } from "../helper_functions";
import { isMethodsDesignFollowUp, isMethodsDesignOther } from "./Types/methods_design";
import { isSampleSizeStandard } from "./Types/methods_sample_size";
import { isSettingBaseline, isSettingCardio, isSettingMRI, isSettingNeuro, isSettingOther, isSettingYearly } from "./Types/methods_setting";
import { ProposalKeys, getInitialNulledData } from "./Types/types_ProposalData";
export class Proposal_base {
    proposal_data;
    constructor() {
        this.proposal_data = getInitialNulledData();
    }
    validate_id() {
        if (this.proposal_data.id === null) {
            throw new Error("Proposal id isn't set. It is not possible to make any request");
        }
    }
    onMissingAttribute(attribute_id) {
        if (!this.proposal_data.hasOwnProperty(attribute_id)) {
            throw new Error(`proposal has no property ${attribute_id}`);
        }
    }
    getProposalAttribute(attribute_id) {
        if (!isOneOf(attribute_id, ProposalKeys))
            throw new Error(`the provided attribute_id ${attribute_id} does not exist on ProposalData`);
        return this.proposal_data[attribute_id];
    }
    //not sure if that works as such or if I can check if the content has the type of the
    setProposalAttribute(attribute_id, attribute_content) {
        const attribute_candidate = {};
        attribute_candidate[attribute_id] = attribute_content;
        //tmp fix to not use typia.is
        if (objectKeysValid(attribute_candidate, ProposalKeys)) {
            this.onMissingAttribute(attribute_id);
            this.proposal_data[attribute_id] = attribute_content;
        }
        else {
            throw new Error(`the combination of key ${stringify(attribute_id)} and value ${stringify(attribute_content)} does not match any expected ProposalAttribute pattern`);
        }
    }
    hasProspectiveFollowUp() {
        return isMethodsDesignFollowUp(this.getProposalAttribute('methods_design'));
    }
    isSampleSizeNonStandard() {
        return !isSampleSizeStandard(this.getProposalAttribute('methods_sample_size'));
    }
    hasDesignOther() {
        return isMethodsDesignOther(this.proposal_data.methods_design);
    }
    hasSettingBaseline() {
        return isSettingBaseline(this.proposal_data.methods_setting_baseline);
    }
    hasSettingYearly() {
        return isSettingYearly(this.proposal_data.methods_setting_yearly);
    }
    hasSettingMRI() {
        return isSettingMRI(this.proposal_data.methods_setting_mri);
    }
    hasSettingMRIcardio() {
        return isSettingCardio(this.proposal_data.methods_setting_mri_cardio);
    }
    hasSettingMRIneuro() {
        return isSettingNeuro(this.proposal_data.methods_setting_mri_neuro);
    }
    hasSettingOther() {
        return isSettingOther(this.proposal_data.methods_setting_other);
    }
    areBiomaterialsNeeded() {
        return isOneOf((this.proposal_data.need_bio), eval_true_values);
    }
    setProposalData(collection) {
        for (const [attribute_id, attribute_content] of Object.entries(collection)) {
            this.setProposalAttribute(attribute_id, attribute_content);
        }
    }
}
