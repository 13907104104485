import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { NavigateBack, Submit } from "../buttons/menu_area_buttons";
import { BasicMenuAreaFormStyling, FormInputsWithPositioning, HeaderInputsWithPositioning } from "./form_layouts";
import { HideExceptAdmin, HideExceptAuth } from "../../authentication/require_auth";
import { useAuth } from "../../authentication/auth_provider";
import { usePageLayout } from "../page_layouts";
import { VariableProposalMapper } from "../variablenmanual/variable_sorter";
import { variablemanual_standalone_association_value } from "../variablenmanual/variablemanual";

function NavEntry({ Wrapper, text, nav_target, nav_callback, permission }) {
  const navigate = useNavigate();
  let entry = <span onClick={(e) => {
    if (nav_target !== undefined) {
      navigate(nav_target);
    }
    if (nav_callback !== undefined) {
      nav_callback();
    }

  }}><Wrapper>{text}</Wrapper></span>

  if (permission === "all" || permission === undefined) return entry;
  if (permission === "auth") return (
    <HideExceptAuth>
      {entry}
    </HideExceptAuth>
  );
  if (permission === "admin") return (
    <HideExceptAdmin>
      {entry}
    </HideExceptAdmin>
  );

}

function AccountVerwaltungInputs({ Wrapper, back_callback }) {
  return <HeaderInputsWithPositioning>
    <NavigateBack callback={back_callback} />
    <NavEntry Wrapper={Wrapper} text="Change Password" nav_target="/account/changePassword" permission={"auth"} />
    <NavEntry Wrapper={Wrapper} text="Set Password for User" nav_target="account/setUserPassword" permission={"admin"} />
    <NavEntry Wrapper={Wrapper} text={"Add new User"} nav_target={"/account/addNewUser"} permission={"admin"} />
  </HeaderInputsWithPositioning>
}

function AnträgeVorhabenInputs({ Wrapper, back_callback }) {
  return <HeaderInputsWithPositioning>
    <NavigateBack callback={back_callback} />
    <NavEntry Wrapper={Wrapper} text="Issue Proposal" nav_target="/proposals/issueProposal" />
    <NavEntry Wrapper={Wrapper} text="Add Announcement" nav_target="/proposals/noteIntention" />
    <NavEntry Wrapper={Wrapper} text="Proposal Overview" nav_target="/proposals/overview" />
    <NavEntry Wrapper={Wrapper} text="Add missing Proposal" nav_target="proposals/antrag_nachtragen" permission={"admin"} />
  </HeaderInputsWithPositioning>
}

function PublicationInputs({ Wrapper, back_callback }) {
  return <HeaderInputsWithPositioning>
    <NavigateBack callback={back_callback} />
  </HeaderInputsWithPositioning>
}

function VariableManualInputs({ Wrapper, back_callback, association, activateOverlayModal = { activateOverlayModal } }) {
  return <HeaderInputsWithPositioning>
    <NavigateBack callback={back_callback} />
    <NavEntry
      text={"Associate with proposal"}
      Wrapper={Wrapper}
      nav_callback={() => {
        activateOverlayModal(<VariableProposalMapper association={association} />)
      }} />
  </HeaderInputsWithPositioning>
}


function InitialInputs({ callback, navigate, Wrapper, association, activateOverlayModal }) {
  const nav_back_inputs = <InitialInputs callback={callback} associatio={association} navigate={navigate} Wrapper={Wrapper} />;
  return <HeaderInputsWithPositioning>
    <NavEntry Wrapper={Wrapper} text="Account Management" nav_target="/account" nav_callback={() => {
      callback(
        <AccountVerwaltungInputs Wrapper={Wrapper} back_callback={() => { callback(nav_back_inputs) }} />
      )
    }} />
    <NavEntry Wrapper={Wrapper} text="Proposals" nav_target="/proposals" nav_callback={() => {
      callback(
        <AnträgeVorhabenInputs Wrapper={Wrapper} back_callback={() => { callback(nav_back_inputs) }} />
      )
    }} />
    <NavEntry Wrapper={Wrapper} text="Variable Manual" nav_target="/variablemanual" nav_callback={() => {
      callback(
        <VariableManualInputs Wrapper={Wrapper} back_callback={() => { callback(nav_back_inputs) }}
          activateOverlayModal={activateOverlayModal} association={association} />
      )
    }} />
    <NavEntry Wrapper={Wrapper} text="Publications" nav_target="/publications" nav_callback={() => {
      callback(
        <PublicationInputs Wrapper={Wrapper} back_callback={() => { callback(nav_back_inputs) }} />
      )
    }} />
  </HeaderInputsWithPositioning>

}

export function NavFormTop(props) {
  const { role } = useAuth();
  const navigate = useNavigate();
  const { activateOverlayModal } = usePageLayout();

  const [inputs, updateInputs] = useState(null);

  useEffect(() => {
    updateInputs(
      <InitialInputs
        activateOverlayModal={activateOverlayModal}
        association={variablemanual_standalone_association_value}
        callback={updateInputs} navigate={navigate} Wrapper={({ children }) => { return <span>{children}</span> }} />
    )
  }, [])

  return (
    <>
      {inputs}
      <p>test</p>
    </>
  )

}

//migrated
export function NavForm(props) {
  const { role } = useAuth();
  const navigate = useNavigate();
  const { activateOverlayModal } = usePageLayout();

  const accountVerwaltungInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
      <Submit button_text="Change Password" callback={() => { navigate("/account/changePassword") }} />
      <HideExceptAdmin>
        <Submit button_text="Set Password for user" callback={() => { navigate("/account/setUserPassword") }} />
      </HideExceptAdmin>
      <HideExceptAdmin>
        <Submit button_text="Add new user" callback={() => { navigate("/account/addNewUser") }} />
      </HideExceptAdmin>
    </FormInputsWithPositioning>

  const anträgeVorhabenInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
      <Submit callback={() => { navigate("/proposals/issueProposal") }} button_text="Issue Proposal" />
      <Submit callback={() => { navigate("/proposals/noteIntention") }} button_text="Note Intention" />
      <Submit callback={() => { navigate("/proposals/overview") }} button_text="Proposal Overview" />
      <HideExceptAdmin>
        <Submit callback={() => { navigate("/proposals/antrag_nachtragen") }} button_text="Add missing Proposal" />
      </HideExceptAdmin>
    </FormInputsWithPositioning>

  const initialInputs = <FormInputsWithPositioning>
    <Submit callback={() => { updateInputs(accountVerwaltungInputs); navigate("/account") }} button_text="Account Management" />
    <Submit callback={() => { updateInputs(anträgeVorhabenInputs); navigate("/proposals") }} button_text="Proposals" />
    <Submit button_text="Variable Manual" callback={() => { updateInputs(variableManualInputs); navigate("/variablemanual") }} />
    <Submit button_text="Publications" callback={() => { updateInputs(publikationenInputs); navigate("/publications") }} />
  </FormInputsWithPositioning>

  const publikationenInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs) }} />
    </FormInputsWithPositioning>

  const variableManualInputs =
    <FormInputsWithPositioning>
      <NavigateBack callback={() => { updateInputs(initialInputs); }} />
      {/*<Submit button_text="Export Selection"/>*/}
      <Submit button_text="Associate with proposal"
        callback={() => { activateOverlayModal(<VariableProposalMapper association={variablemanual_standalone_association_value} />) }} />
    </FormInputsWithPositioning>

  const [inputs, updateInputs] = useState(initialInputs);

  return (
    <BasicMenuAreaFormStyling>
      {inputs}
    </BasicMenuAreaFormStyling>
  )
}
