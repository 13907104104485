import { eval_true_values } from "../../custom_types";
import { isOneOf } from "../../helper_functions";
export function isSettingYearly(elem) {
    return isOneOf(elem, [
        'yearly',
        'yearly questionaire',
        'Yearly',
        'Yearly questionaire',
        'yearly Questionaire',
        'Yearly Questionaire',
    ]) || isOneOf(elem, eval_true_values);
}
export function isSettingMRI(elem) {
    return isOneOf(elem, [
        'MRI',
        'mri',
        'MRT',
        'mrt',
    ]) || isOneOf(elem, eval_true_values);
}
export function isSettingBaseline(elem) {
    return isOneOf(elem, [
        'Baseline',
        'baseline'
    ]) || isOneOf(elem, eval_true_values);
}
export function isSettingCardio(elem) {
    return isOneOf(elem, [
        'cardio',
        'Cardio'
    ]) || isOneOf(elem, eval_true_values);
}
export function isSettingNeuro(elem) {
    return isOneOf(elem, [
        'neuro',
        'Neuro'
    ]) || isOneOf(elem, eval_true_values);
}
export function isSettingOther(elem) {
    return isOneOf(elem, [
        'other',
        'Other'
    ]) || isOneOf(elem, eval_true_values);
}
