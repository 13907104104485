import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
import { VMHistoryManager } from "../Managers/VMHistoryManager";
import { useCurrentNodes } from "./CurrentNodesContext";
import { isOneOf } from "../helper_functions";
const VMHistoriesContext = createContext('initialising');
export const VMHistoriesProvider = ({ children }) => {
    const [forceUpdateClock, setForceUpdateClock] = useState(false);
    const [historyManager, setHistoryManager] = useState(new VMHistoryManager());
    const [skipHistoryManagerUpdate, setSkipHistoryManagerUpdate] = useState(false);
    const { nodesManager, setNodesManager, forceUpdateClock: currentNodesUpdateClock } = useCurrentNodes();
    const getLastHistoryElements = (pid, kind, numberOfElements) => {
        return historyManager.getLastHistoryElements(pid, kind, numberOfElements);
    };
    const Effect_updateHistory = () => {
        if (skipHistoryManagerUpdate) {
            setSkipHistoryManagerUpdate(false);
            return;
        }
        let { proposal_id, kind, node_id } = nodesManager.getLastTouchedElements();
        if (isOneOf("", [node_id, proposal_id, kind]))
            return;
        let newManager = historyManager.addToHistory(proposal_id, kind, node_id);
        setHistoryManager(newManager);
        setForceUpdateClock(!forceUpdateClock);
    };
    const jumpBackInHistory = (pid, kind, vid) => {
        let { revertStatus, manager } = historyManager.revertToLastIdInHistory(pid, kind, vid);
        if (!revertStatus)
            return;
        let newNodeManager = nodesManager.setCurrentNode(pid, kind, vid);
        setHistoryManager(manager);
        setSkipHistoryManagerUpdate(true);
        setForceUpdateClock(!forceUpdateClock);
        setNodesManager(newNodeManager);
    };
    const expose_value = {
        jumpBackInHistory: jumpBackInHistory,
        getLastHistoryElements: getLastHistoryElements,
        forceUpdateClock: forceUpdateClock
    };
    useEffect(() => {
        Effect_updateHistory();
    }, [currentNodesUpdateClock]);
    return (_jsx(VMHistoriesContext.Provider, { value: expose_value, children: children }));
};
export const useVMHistories = () => { return useContext(VMHistoriesContext); };
