import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from "react";
import { Node } from "../Node";
import { useGraph } from "./GraphContext";
import { useCurrentNodes } from "./CurrentNodesContext";
const CurrentVMLinksContext = createContext('initializing');
export const CurrentVMLinksProvider = ({ children }) => {
    const graphContext = useGraph();
    const currentVariablesContext = useCurrentNodes();
    const [contextLinkedFromID, newContextLinkedFromID] = useState([]);
    const [contextLinkingToID, newContextLinkingToID] = useState([]);
    const [variablesLinkedFromID, newVariablesLinkedFromID] = useState([]);
    const [variablesLinkingToID, newVariablesLinkingToID] = useState([]);
    const handleCurrentIDChange = (id) => {
        let linkedFrom = graphContext.getNodesLinkedFromID(id);
        let linkedTo = graphContext.getNodesLinkingToID(id);
        let clf = {};
        let clt = {};
        let vlf = {};
        let vlt = {};
        for (const node of linkedFrom) {
            if (Node.isVariable(node)) {
                vlf[node.id] = node;
            }
            if (Node.isVMContext(node)) {
                clf[node.id] = node;
            }
        }
        for (const node of linkedTo) {
            if (Node.isVariable(node)) {
                vlt[node.id] = node;
            }
            if (Node.isVMContext(node)) {
                clt[node.id] = node;
            }
        }
        newContextLinkedFromID(Object.values(clf));
        newContextLinkingToID(Object.values(clt));
        newVariablesLinkedFromID(Object.values(vlf));
        newVariablesLinkingToID(Object.values(vlt));
    };
    useEffect(() => {
        //todo implement
    }, []);
    useEffect(() => {
        //todo implement
    }, [currentVariablesContext.forceUpdateClock]);
    const expose_value = {
        contextLinkedFrom: contextLinkedFromID,
        contextLinkedTo: contextLinkingToID,
        variablesLinkedFrom: variablesLinkedFromID,
        variablesLinkedTo: variablesLinkingToID
    };
    return (_jsx(CurrentVMLinksContext.Provider, { value: expose_value, children: children }));
};
