import { PageLayoutNavigationAreaTop } from "./page_layouts";
import { ContentRoutes, MenuRoutes } from "./routes";


function LandingPage(props){
    return (
        <PageLayoutNavigationAreaTop
            content={<ContentRoutes/>}
            menu={<MenuRoutes />}
        />
    )
}

export default LandingPage;
