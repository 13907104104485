import { isDetail, isDetails, isVisibility } from "./custom_types";
import { deepEqual, isNonNullObject, isOneOf, isString, objectHasKeys, objectKeysValid, stringify, structuredClone } from "./helper_functions";
import { HelperNodeSorter } from "./helper_NodeSorter";
import { GetResponseShapeError, fetch_json_endpoint, isResponse, onErrorResponse } from "./RequestHelpers";
export function isNodeLike(maybeNode) {
    return (objectHasKeys(maybeNode, ["id", "type", "visibility", "display_name", "details"]) &&
        isString(maybeNode.id) &&
        isString(maybeNode.type) &&
        isVisibility(maybeNode.visibility) &&
        isString(maybeNode.display_name) &&
        isDetails(maybeNode.details));
}
export class Node {
    id;
    type;
    visibility;
    display_name;
    details;
    initial_details;
    fetched;
    listeners_callbacks;
    constructor(id, details, type, visibility, display_name) {
        this.id = id;
        this.type = type;
        this.visibility = visibility;
        this.display_name = display_name;
        this.initial_details = this.assignInitialDetails(details);
        this.details = structuredClone(this.initial_details);
        this.listeners_callbacks = [];
        this.fetched = false;
    }
    add_change_listener(callback) {
        this.listeners_callbacks.push(callback);
    }
    inform_listeners() {
        for (const callback of this.listeners_callbacks) {
            callback(this);
        }
    }
    async db_fetch_group(jwt, requestor_mail, parent_group_found_callback, member_group_found_callback) {
        this.type = "context";
        let id = this.id.replace("group_", "");
        let request_body = { "id": id, "jwt": jwt, "mail": requestor_mail };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/vm/get_group_details.php", request_body);
        console.log(JSON.stringify(response));
        if (!(isResponse(response) &&
            response.hasOwnProperty('request_details') &&
            response.request_details.hasOwnProperty('id') &&
            response.request_details.hasOwnProperty('name') &&
            response.request_details.hasOwnProperty('description') &&
            response.request_details.hasOwnProperty('parents') &&
            response.request_details.hasOwnProperty('members'))) {
            throw GetResponseShapeError(response);
        }
        onErrorResponse(response);
        this.display_name = response.request_details['name'];
        this.details = [
            { attributeName: 'Group Name',
                attributeContent: response.request_details['name'],
                visibility: "visible" },
            { attributeName: 'description',
                attributeContent: response.request_details['description'],
                visibility: "visible" },
            { attributeName: 'parents',
                attributeContent: JSON.stringify(response.request_details['parents']),
                visibility: "visible" },
            { attributeName: 'members',
                attributeContent: JSON.stringify(response.request_details['members']),
                visibility: "visible" },
        ];
        for (const parent of response.request_details['parents']) {
            if (parent_group_found_callback !== undefined) {
                parent_group_found_callback(parent);
            }
        }
        for (const member of response.request_details['members']) {
            if (member_group_found_callback !== undefined) {
                member_group_found_callback(member);
            }
        }
        this.inform_listeners();
    }
    async db_fetch_variable(jwt, requestor_mail) {
        this.type = "variable";
        let id = this.id.replace("var_", "");
        let request_body = { "id": id, "jwt": jwt, "mail": requestor_mail };
        const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/vm/get_attributes_for_variable.php", request_body);
        console.log(JSON.stringify(response));
        if (!(isResponse(response) &&
            response.hasOwnProperty('request_details') &&
            response.request_details.hasOwnProperty('variable_values'))) {
            throw GetResponseShapeError(response);
        }
        onErrorResponse(response);
        let details = [];
        for (const column_name of response.request_details['column_names']) {
            let attribute = response.request_details['variable_values'][0][column_name];
            if (column_name === "Langname") {
                this.display_name = attribute;
            }
            console.log("attributeName ", column_name);
            console.log("attributeValue ", attribute);
            let detail = { attributeName: column_name, attributeContent: attribute, visibility: "visible" };
            details.push(detail);
        }
        this.details = details;
        this.inform_listeners();
    }
    async db_fetch_self(jwt, requestor_mail, parent_group_found_callback, member_group_found_callback) {
        try {
            if (!isString(this.id)) {
                console.error(`node id is no string but was expected to be one\n\tnode is ${JSON.stringify(this)}`);
            }
            if (isString(this.id) && this.id.startsWith("var_")) {
                this.db_fetch_variable(jwt, requestor_mail);
            }
            if (isString(this.id) && this.id.startsWith("group_")) {
                this.db_fetch_group(jwt, requestor_mail, parent_group_found_callback, member_group_found_callback);
            }
            this.fetched = true;
        }
        catch (error) {
            throw error;
        }
    }
    assignInitialDetails(details) {
        let details_copy = [];
        for (const detail of details) {
            details_copy.push(detail);
        }
        return details_copy;
    }
    getNodeCopy() {
        return new Node(this.id, structuredClone(this.details), this.type, this.visibility, this.display_name);
    }
    static isVMContext(node) {
        return (node !== null && node !== undefined &&
            (node.type === "context" ||
                node.id.startsWith("Var-Manual-Kontext_") ||
                isOneOf(node.id, [
                    "VM_FB_VM_FB",
                    "VM_Fragebogen_Benjamin",
                    "VM_FB_DIFE_Inworks_VM",
                    "VM_HCHS_Soarian_ALL_RS_@bearbeitet",
                    "VM_Gerätedaten ALL",
                    "Start / Overview",
                    "Root",
                    "Gerätedaten",
                    "Fragebögen",
                    "Untersuchungsergebnisse",
                    "Sekundärvariablen"
                ])));
    }
    static isVariable(node, excludedVariableNames = []) {
        if (node === null || node === undefined)
            return false;
        const id = node.id;
        if (excludedVariableNames.includes(id))
            return false;
        return id !== "" && !Node.isVMContext(node);
    }
    static nodesEqual(n1, n2) {
        console.log("nodes equal n1 = " + stringify(n1) + " n2 = " + stringify(n2));
        if (!isNodeLike(n1) || !isNodeLike(n2))
            return false;
        const data1 = Node.getNodeData(n1);
        const data2 = Node.getNodeData(n2);
        console.log("nodes equal data1 = " + stringify(data1) + " data2 = " + stringify(data2));
        for (const elem of ["id", "type", "visibility", "display_name"]) {
            if (data1[elem] === undefined
                || data2[elem] === undefined
                || data1[elem] !== data2[elem]) {
                throw new Error(`nodesEqual debug fail at elem ${elem}`);
                return false;
            }
        }
        return (data1.hasOwnProperty("details")
            && data2.hasOwnProperty("details")
            && deepEqual(data1.details, data2.details));
    }
    static getNodeData(n) {
        if (n instanceof Node) {
            return n.getNodeCopy();
        }
        return n;
    }
    static getAttributesByName(n) {
        const details = this.getNodeData(n).details;
        let result = {};
        for (const detail of details) {
            result[detail.attributeName] = detail;
        }
        return result;
    }
    static appendDetailContents(c1, c2) {
        return `${c1}\n\n${c2}`;
    }
    static isEmptyDetail(detail) {
        const pattern = /\S/;
        return !pattern.test(detail.attributeContent);
    }
    static filterNodesForPred(nodes, predicate) {
        let result = {};
        for (const node of nodes) {
            if (predicate(node)) {
                result[node.id] = node;
            }
        }
        return Object.values(result);
    }
    static filterForContexts(nodes) {
        return Node.filterNodesForPred(nodes, Node.isVMContext);
    }
    static filterForVariables(nodes) {
        return Node.filterNodesForPred(nodes, Node.isVariable);
    }
    static filterEmptyDetails(details) {
        let filtered = [];
        for (const detail of details) {
            if (!Node.isEmptyDetail(detail)) {
                filtered.push(detail);
            }
        }
        return filtered;
    }
    static detailContainsSearchString(detail, search) {
        search = search.toLowerCase();
        if (detail.attributeName.toLowerCase().includes(search))
            return true;
        if (detail.attributeContent.toLowerCase().includes(search))
            return true;
        return false;
    }
    static searchStringMatchInDetails(search, details) {
        let match = false;
        while (!match && details.length > 0) {
            const detail = details.pop();
            match = this.detailContainsSearchString(detail, search);
        }
        return match;
    }
    static nodeMatchesSearch(node, searchList) {
        const details = Node.getNodeData(node).details;
        //only check further for all search terms that are not already found in display name or id
        let reducedList = searchList.filter((elem) => {
            elem = elem.toLowerCase();
            return !(node.id.toLowerCase().includes(elem) || node.display_name.toLowerCase().includes(elem));
        });
        let hasMissmatch = false;
        while (reducedList.length > 0 && !hasMissmatch) {
            const search = reducedList.pop();
            hasMissmatch = !this.searchStringMatchInDetails(search, structuredClone(details));
        }
        return !hasMissmatch;
    }
    static getDisplayName(n) {
        return n.display_name;
    }
    static compareDisplayName(n1, n2) {
        return n1.display_name.localeCompare(n2.display_name);
    }
    static isNodeDetail(det) {
        //is seems to have some bug, since testing the function returns true for possible detail {attributeContent: "content", attributeName: "name", other: "visible"}
        return (isNonNullObject(det) &&
            objectKeysValid(det, ["attributeName", "attributeContent", "visibility"]) &&
            isDetail(det));
    }
    getDetail(attributeName) {
        for (const detail of this.details) {
            if (detail.attributeName === attributeName)
                return detail;
        }
        return false;
    }
    static mergeDetails(details1, details2) {
        let details_by_name = details1.reduce((akk, detail) => ({ ...akk, [detail.attributeName]: detail }), {});
        for (const detail2 of details2) {
            const name = detail2.attributeName;
            if (!details_by_name.hasOwnProperty(name)) {
                details_by_name[name] = detail2;
            }
            else {
                details_by_name[name] = {
                    attributeName: name,
                    attributeContent: Node.appendDetailContents(details_by_name[name].attributeContent, detail2.attributeContent),
                    visibility: detail2.visibility
                };
            }
        }
        return Object.values(details_by_name);
    }
    static sortNodes(nodes) {
        return nodes;
        let actual_nodes = nodes.map((node) => {
            if (Node.isNode(node))
                return node;
            let nodelike = node;
            return new Node(nodelike.id, nodelike.details, nodelike.type, nodelike.visibility, nodelike.display_name);
        });
        return HelperNodeSorter.sortNodes(actual_nodes);
    }
    static isNode(n) {
        return (n instanceof Node);
    }
    static isNodeLike(n) {
        return isNodeLike(n);
    }
    static getNodesById(nodes) {
        return Object.fromEntries(nodes.map((node) => {
            let nCopy = node.getNodeCopy();
            return [nCopy.id, nCopy];
        }));
    }
}
