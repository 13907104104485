import { PaddedBorderSlimBlack } from "./themes and styles/borders";
import homePageImage from "../img/home_page_image.png";
import { Padding } from "./text_display/main_content_text_styling";
import { Bold } from "./themes and styles/text_styles";

const intro_text = "Die Hamburg City Health Study (HCHS) ist eine prospektive bevölkerungsbasierte Langzeit-Kohortenstudie, die seit 2016 am Universitätsklinikum Hamburg-Eppendorf (UKE) durchgeführt wird. Sie basiert auf einer Zufallsstichprobe von Hamburger:innen im Alter von 45 bis 75 Jahren. Ziel der Studie ist es, Risikofaktoren für die häufigsten Volkskrankheiten wie Herzinfarkt, Schlaganfall, Krebs, Demenz und Depressionen zu identifizieren, um für die Zukunft bessere Behandlungs- und Präventionsmaßnahmen zu entwickeln. Über 30 Kliniken und Institute des UKE arbeiten hierfür gemeinsam an der HCHS."

export function HomePage(props) {
  return (
      <div>
      <div
        style={{
          "margin": "1rem 0px",
          "display": "flex",
          "justify-content": "center",
          "align-items": "center",
          "width": "auto",
          "height": "auto",
          "overflow": "hidden"
        }}>
        <img src={homePageImage} alt="HCHS sculpture installation"
          style={{
            "max-width": "100%",
            "max-height": "100%",
            "object-fit": "contain"
          }} />
      </div>
      <Padding>
        <p>
        Die Hamburg City Health Study (HCHS) ist eine prospektive bevölkerungsbasierte Langzeit-Kohortenstudie, die seit 2016 am Universitätsklinikum Hamburg-Eppendorf (UKE) durchgeführt wird. Sie basiert auf einer Zufallsstichprobe von Hamburger:innen im Alter von 45 bis 75 Jahren. Ziel der Studie ist es, Risikofaktoren für die häufigsten Volkskrankheiten wie Herzinfarkt, Schlaganfall, Krebs, Demenz und Depressionen zu identifizieren, um für die Zukunft bessere Behandlungs- und Präventionsmaßnahmen zu entwickeln. Über 30 Kliniken und Institute des UKE arbeiten hierfür gemeinsam an der HCHS.
        </p>
        <p>
          Dieses Webportal bietet Ihnen:
        </p>

        <p><Bold>Einen umfassenden Überblick über die HCHS:</Bold> Erfahren Sie alles Wissenswerte über die Ziele, den Ablauf und die Beteiligten der Studie.</p>
        <p><Bold>Einsicht in die Variablenmanuals:</Bold> Finden Sie schnell und unkompliziert die für Sie relevanten Variablen.</p>
        <p><Bold>Unterstützung bei der Antragstellung:</Bold> Stellen Sie direkt online Ihren Nutzungsantrag und wählen Sie dabei bequem die benötigten Variablen aus.</p>
      </Padding>
      <p>{intro_text}</p>
      <p>{intro_text}</p>
      <p>{intro_text}</p>
    </div>
  )
}
