import { Link } from "./Link";
import { Node } from "./Node";
import { GetResponseShapeError, fetch_json_endpoint, isResponse, onErrorResponse } from "./RequestHelpers";
import { isString, structuredClone } from "./helper_functions";
export class Graph {
    referenceNodes;
    referenceLinks;
    rootNodeIds;
    nodesById;
    linksById;
    constructor(nodes, links, rootNodeIds = []) {
        this.referenceNodes = Graph.createReferenceListFromNodes(nodes);
        this.referenceLinks = Graph.createReferenceListFromLinks(links);
        this.rootNodeIds = rootNodeIds;
        this.nodesById = Node.getNodesById(this.referenceNodes);
        this.linksById = Link.getLinksById(this.referenceLinks);
    }
    static isGraph(graph) {
        return graph instanceof Graph;
    }
    static isNode(node) {
        return Node.isNodeLike(node);
    }
    static isLink(link) {
        return Link.isLinkLike(link);
    }
    static memberOfNodeSelection(node, selection) {
        for (const selected of selection) {
            if (Graph.nodesEqual(node, selected))
                return true;
        }
        return false;
    }
    static memberOfLinkSelection(link, selection) {
        for (const selected of selection) {
            if (Graph.linksEqual(link, selected))
                return true;
        }
        return false;
    }
    static createReferenceListFromNodes(nodes) {
        let nodeList = [];
        for (const node of nodes) {
            nodeList.push(new Node(node.id, structuredClone(node.details), node.type, node.visibility, node.display_name));
        }
        return nodeList;
    }
    static createReferenceListFromLinks(links) {
        let linkList = [];
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            linkList.push(new Link(i.toString(), link.source, link.target));
        }
        return linkList;
    }
    linkExists(link) {
        return Link.oneOfLinks(link, this.referenceLinks);
    }
    nodeExists(node_id) {
        return this.nodesById.hasOwnProperty(node_id);
    }
    static nodesEqual(node1, node2) {
        return Node.nodesEqual(node1, node2);
    }
    static linksEqual(link1, link2) {
        return Link.linksEqual(link1, link2);
    }
    getNodeForId(id, auth_token, requestor_mail, node_change_listener) {
        console.log(`getNodeForId id is ${id}`);
        if (id === undefined) {
            console.log("id is undefined");
            throw new Error("id is undefined");
        }
        if (auth_token === undefined)
            auth_token = "";
        if (requestor_mail === undefined)
            requestor_mail = "";
        if (this.nodeExists(id)) {
            if (!this.nodesById[id].fetched) {
                this.nodesById[id].db_fetch_self(auth_token, requestor_mail);
            }
            return Node.getNodeData(this.nodesById[id]);
        }
        try {
            console.log("before node fetching");
            //let node = new Node(id, [], "unknown", "hidden", "unknown");
            let node = this.addNewNode(id, [], "unknown", "hidden", "unknown");
            if (node_change_listener !== undefined) {
                node.add_change_listener(node_change_listener);
            }
            console.log(`node is ${JSON.stringify(node)}`);
            node.db_fetch_self(auth_token, requestor_mail, (parent_group) => {
                this.addParentGroup(parent_group, id);
            }, (member_group) => {
                this.addMemberGroup(member_group, id);
            });
            if (id.startsWith("var_")) {
                this.db_get_groups_for_variable(id, auth_token, requestor_mail);
            }
            console.log(`node is ${JSON.stringify(node)}`);
            console.log("after node fetching");
            return node;
        }
        catch (error) {
            return new Node(id, [], "unknown", "hidden", "unknown");
        }
    }
    getNodes() {
        let nodes = [];
        for (const refNode of this.referenceNodes) {
            nodes.push(refNode.getNodeCopy());
        }
        return nodes;
    }
    getLinks() {
        let links = [];
        for (const refLink of this.referenceLinks) {
            links.push(refLink.getLinkCopy());
        }
        return links;
    }
    // all nodes that link to the provided id
    getLinkedFromNodes(id) {
        let nodes = [];
        for (const refLink of this.referenceLinks) {
            if (refLink.isLinkedTo(id)) {
                nodes.push(Node.getNodeData(this.nodesById[refLink.source]));
            }
        }
        return nodes;
    }
    // all nodes that the provided id links to
    getLinkedToNodes(id) {
        let nodes = [];
        for (const refLink of this.referenceLinks) {
            if (refLink.isLinkedFrom(id)) {
                nodes.push(Node.getNodeData(this.nodesById[refLink.target]));
            }
        }
        return nodes;
    }
    is_valid_group(maybe_group) {
        return (maybe_group.hasOwnProperty('valid_group') &&
            maybe_group['valid_group'] === true &&
            maybe_group.hasOwnProperty('name') &&
            maybe_group.hasOwnProperty('id') &&
            maybe_group.hasOwnProperty('description'));
    }
    addMemberGroup(maybe_group, node_id) {
        //throw new Error(`${JSON.stringify(maybe_group)} ${node_id}`);
        if (this.is_valid_group(maybe_group)) {
            console.warn("group is valid");
            let vm_group = maybe_group;
            vm_group.id = `group_${vm_group.id}`;
            this.addNewNode(vm_group.id, [], "context", "visible", vm_group.name);
            this.addNewLink(node_id, vm_group.id);
        }
    }
    addParentGroup(maybe_group, node_id) {
        //throw new Error(`${JSON.stringify(maybe_group)} ${node_id}`);
        if (this.is_valid_group(maybe_group)) {
            console.warn("group is valid");
            let vm_group = maybe_group;
            vm_group.id = `group_${vm_group.id}`;
            this.addNewNode(vm_group.id, [], "context", "visible", vm_group.name);
            this.addNewLink(vm_group.id, node_id);
        }
    }
    async db_get_groups_for_variable(node_id, auth_token, requestor_mail) {
        let id = node_id;
        if (node_id.startsWith("var_")) {
            id = node_id.replace("var_", "");
        }
        try {
            let request_body = { "id": id, "jwt": auth_token, "mail": requestor_mail };
            console.log(`request_body is ${JSON.stringify(request_body)}`);
            const response = await fetch_json_endpoint("https://api.hchs.hamburg/api_endpoints/vm/get_groups_for_variable.php", request_body);
            if (!(isResponse(response) &&
                response.hasOwnProperty('request_details') &&
                response.request_details.hasOwnProperty('groups'))) {
                throw GetResponseShapeError(response);
            }
            onErrorResponse(response);
            let groups = response['request_details']['groups'];
            console.log(`groups are ${JSON.stringify(groups)}`);
            for (const group of groups) {
                this.addParentGroup(group, node_id);
            }
            return groups;
        }
        catch (error) {
            console.log(`error during fetching of groups for variable ${error}`);
            throw error;
        }
    }
    db_get_related_nodes(id) {
    }
    getNodeSelectionFromIds(ids) {
        let nodes = [];
        for (const id of ids) {
            if (this.nodeExists(id)) {
                let node = this.nodesById[id];
                nodes.push(Node.getNodeData(node));
            }
        }
        return nodes;
    }
    getLinksForNodeIDSelection(node_ids) {
        let links = [];
        for (const link of this.referenceLinks) {
            if (link.hasPairIn(node_ids)) {
                links.push(link.getLinkCopy());
            }
        }
        return links;
    }
    getNeigborhoodNodeIDsForNode(id) {
        if (!this.nodeExists(id))
            return [];
        let id_collector = {};
        for (const link of this.referenceLinks) {
            if (link.isLinked(id)) {
                id_collector[link.getLinkPartner(id)] = true;
            }
        }
        return Object.keys(id_collector);
    }
    getNeigborhoodNodeIDsForNodes(ids) {
        let id_collector = {};
        for (const link of this.referenceLinks) {
            for (const id of ids) {
                if (link.isLinked(id)) {
                    id_collector[link.getLinkPartner(id)] = true;
                }
            }
        }
        return Object.keys(id_collector);
    }
    getNeigborhoodNodeIDs(node_id_or_list) {
        if (isString(node_id_or_list)) {
            return this.getNeigborhoodNodeIDsForNode(node_id_or_list);
        }
        return this.getNeigborhoodNodeIDsForNodes((node_id_or_list));
    }
    getNeigborhoodGraph(node_id_or_list) {
        let neighborIDs = this.getNeigborhoodNodeIDs(node_id_or_list);
        let returnLinks = this.getLinksForNodeIDSelection(neighborIDs);
        let returnNodes = this.getNodeSelectionFromIds(neighborIDs);
        return { nodes: returnNodes, links: returnLinks };
    }
    getRootGraph() {
        if (this.rootNodeIds.length == 0) {
            return this.getFullGraph();
        }
        return this.getFullGraph();
        return this.getNeigborhoodGraph(this.rootNodeIds);
    }
    getFullGraph() {
        return { nodes: this.getNodes(), links: this.getLinks() };
    }
    getNodeIDsMatchingSearch(search) {
        //remove duplicate whitespaces
        search = search.replace(/\s+/g, ' ');
        let searchTerms = search.split(" ");
        let result_ids = [];
        for (const node of this.referenceNodes) {
            if (Node.nodeMatchesSearch(node, searchTerms)) {
                result_ids.push(node.id);
            }
        }
        return result_ids;
    }
    addSearchNode(search) {
        let foundIDs = this.getNodeIDsMatchingSearch(search);
        let display_name = `Search Term: ${search}`;
        this.addNewNode(display_name, [
            {
                attributeName: "Description",
                attributeContent: `This search links to each variable and context that matches with the provided search term \n\t${search}`,
                visibility: "visible"
            }
        ], "context", "visible", display_name);
        for (const id of foundIDs) {
            this.addNewLink(display_name, id);
        }
        return this;
    }
    addNewLink(source, target) {
        if (Link.oneOfLinks({ source: source, target: target }, this.referenceLinks)) {
            return this;
        }
        this.referenceLinks.push(new Link(this.referenceLinks.length.toString(), source, target));
        return this;
    }
    replaceReferenceNode(node) {
        //remove existing node from referenceNodes
        let new_reference_nodes = this.referenceNodes.filter((current_node) => {
            return current_node.id !== node.id;
        });
        //add changed node to referenceNodes
        new_reference_nodes.push(node);
        this.referenceNodes = new_reference_nodes;
    }
    addNewNode(node_id, node_details, type, visibility, display_name) {
        if (this.nodeExists(node_id)) {
            let existing_node = this.getNodeForId(node_id);
            node_details = Node.mergeDetails(existing_node.details, node_details);
        }
        let node = new Node(node_id, node_details, type, visibility, display_name);
        this.replaceReferenceNode(node);
        this.nodesById[node_id] = node;
        return node;
    }
}
