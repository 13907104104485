import { isOneOf } from "typescript_helpers";
import { Collection, getAttribute, SingleChoice, Text } from "./structuring_elements"


const single_choice_cooperation = new SingleChoice(
  'Internal / External Cooperation Partners',
  'other_partners',
  [
    {
      optionName: 'Yes',
      optionConditionalContent: new Text('Partners', 'other_partners')
    },
    {
      optionName: 'No',
      optionConditionalContent: false
    }
  ],
  (proposal, content_key, name) => {
    const attribute_value = getAttribute(proposal, content_key);
    if(name === 'Yes'){
      return !isOneOf(attribute_value, ['No', 'no', 'false', false, ""]);
    }
    if(name === 'No'){
      return isOneOf(attribute_value, ['No', 'no', 'false', false, ""]);
    }
    return false;
  }
)

export const collection_further_information = new Collection(
  'Further Information',
  [
    single_choice_cooperation,
    new Text(
      'Preliminary List Of Authors For Publication',
      'other_manuscript'
    )
  ]
)
