import styled from "styled-components";
import { ThemeColor } from "../themes and styles/colors";

const RedBarBase = styled.span`
  border-radius: 10px;
  border-radius: 10px;
  background-color: ${ThemeColor.red};
  border-style: solid;
  border-color: ${ThemeColor.red};
  display: inline-block;
  justify-self: center;

`

const GreyBarBase = styled.span`
  border-radius: 10px;
  background-color: ${ThemeColor.grey.normal};
  border-style: solid;
  border-color: ${ThemeColor.grey.normal};
  display: inline-block;
  border-width: 1px;
  justify-self: center;
`

export const GreyDividerBar = styled(GreyBarBase)`
  width: ${props => props.width || "100%"};
  height: 1px;
  margin: 0px 1.5rem;
`

export const RedDividerBar = styled(RedBarBase)`
  width: ${props => props.width || "100%"};
  height: 2px;

`

export const RedPartitionBar = styled(RedBarBase)`
  height: ${props => props.height || "100%"};
  width: 2px;
`
