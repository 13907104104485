import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import { CurrentNodeManager } from "../Managers/CurrentNodeManager";
const CurrentNodeContext = createContext('initializing');
export const CurrentNodesProvider = ({ children }) => {
    const [nodesManager, setNodesManager] = useState(new CurrentNodeManager());
    const [forceUpdateClock, setForceUpdateClock] = useState(true);
    const expose_setManager = (newManager) => {
        setNodesManager(newManager);
        setForceUpdateClock(!forceUpdateClock);
    };
    const expose_value = {
        nodesManager: nodesManager,
        setNodesManager: expose_setManager,
        forceUpdateClock: forceUpdateClock
    };
    return (_jsx(CurrentNodeContext.Provider, { value: expose_value, children: children }));
};
export const useCurrentNodes = () => {
    return useContext(CurrentNodeContext);
};
