export function arrayUnion(arr1, arr2) {
    let concat = arr1.concat(arr2);
    return concat.filter((val, pos) => {
        return concat.indexOf(val) === pos;
    });
}
export function isNonNullObject(obj) {
    return obj !== null && typeof obj === "object";
}
export function isPrimitive(prim) {
    return !isNonNullObject(prim);
}
export function isString(stng) {
    return Object.prototype.toString.call(stng) === "[object String]";
}
export function isList(lst) {
    return Array.isArray(lst);
}
export function objectHasKeys(x, lst) {
    if (x === null || x === undefined) {
        return false;
    }
    for (const key of lst) {
        if (!x.hasOwnProperty(key))
            return false;
    }
    return true;
}
export function objectKeysValid(x, valid_keys) {
    for (const key of Object.keys(x)) {
        if (!valid_keys.includes(key))
            return false;
    }
    return true;
}
export function initObjectKeysAs(x, keys, initValue) {
    for (const key of keys) {
        x[key] = initValue;
    }
    return x;
}
export function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length &&
            ok(x).every((key) => deepEqual(x[key], y[key]))
        : x === y;
}
export function cloneDate(date) {
    let copy = new Date();
    copy.setTime(date.getTime());
    return copy;
}
export function cloneList(list) {
    let copy = [];
    for (let i = 0, len = list.length; i < len; i++) {
        copy[i] = structuredClone(list[i]);
    }
    return copy;
}
export function cloneObject(obj) {
    let copy = {};
    for (const attr in obj) {
        if (obj.hasOwnProperty(attr) && isString(attr)) {
            copy[attr] = structuredClone(obj[attr]);
        }
    }
    return copy;
}
export function structuredClone(obj) {
    if (isPrimitive(obj))
        return obj;
    if (obj instanceof Date)
        return cloneDate(obj);
    if (isList(obj))
        return cloneList(obj);
    if (isNonNullObject(obj))
        return cloneObject(obj);
    throw new Error(`Unexpected argument to structuredClone(obj) - obj object is neither 
  \t primitive (any non object including null), 
  \t Array,
  \t Date or
  \t non null Object
  obj given is ${obj} with typeof obj = ${typeof obj}`);
}
export function isOneOf(blob, candidates) {
    for (const candidate of candidates) {
        if (isNonNullObject(candidate) && isNonNullObject(blob) && deepEqual(blob, candidate))
            return true;
        if (blob === candidate)
            return true;
    }
    return false;
}
export function stringify(blob) {
    return JSON.stringify(blob, null, 2);
}
/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}
export function mailIsValid(mail) {
    var re = /\S+@\S+\.\S+/;
    return re.test(mail);
}
export function hasNumberOfUnsetArguments(number_of_arguments, func) {
    if (!isFunction(func))
        throw new TypeError(`For hasNumberOfUnsetArguments :: expect func to be a function
  func serializes to 
  \t ${JSON.stringify(func)}`);
    return (number_of_arguments === getNumberOfUnsetArguments(func));
}
export function getNumberOfUnsetArguments(func) {
    if (!isFunction(func))
        throw new TypeError(`For getNumberOfUnsetArguments :: expect func to be a function
  func serializes to 
  \t ${JSON.stringify(func)}`);
    return func.length;
}
export function isFunction(maybe_function) {
    return (typeof maybe_function === "function");
}
export function transpose(array_matrix) {
    let result = [];
    for (let i = 0; i < array_matrix.length; ++i) {
        for (let j = 0; j < array_matrix[i].length; ++j) {
            if (array_matrix[i][j] === undefined)
                continue;
            if (result[j] === undefined)
                result[j] = [];
            result[j][i] = array_matrix[i][j];
        }
    }
    return result;
}
export function arrayToCsv(rows) {
    /*
      return rows.map(row =>
        row
          .map(String)
          .map((strng: string) => { return strng.replaceAll('"', '""') })
          .map((strng: string) => `"${strng}"`)
          .join(',')
      ).join('\r\n');
        */
}
// does not work if array elements are equal, but not identical objects
export function simpleArrayEqual(arr1, arr2) {
    if (arr1 === arr2)
        return true;
    if (arr1 == null || arr2 == null)
        return false;
    if (arr1.length !== arr2.length)
        return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
    return true;
}
export function stripMarkdownLinkBrackets(strn) {
    if (strn.includes("[[") && strn.includes("]]")) {
        strn = strn.replace("[[", "");
        strn = strn.replace("]]", "");
    }
    return strn;
}
export function getErrorMessage(error) {
    if (error instanceof Error)
        return error.message;
    return stringify(error);
}
