import { css } from "styled-components";
import styled from "styled-components";
import { GridElement } from "../text_display/main_content_text_styling";
import { ColumnGrid, OverflowContainerVertical, RowGrid } from "../divider and frames/layouts";
import { HeaderSmall } from "../text_display/main_content_text_styling";
import { DownloadIcon, RoundIconWrapper } from "../icons/font_awesome_icons";
import { PaddedBorderSlimBlack } from "../themes and styles/borders";
import { ThemeColor } from "../themes and styles/colors";
import { useGraph, useProposalSelectionMapping } from "./contexts";
import { VariablesCSVExporter } from "../../nonComponentFunctions/pdf_generation/variable_exporting";
import { isList } from "typescript_helpers";
import { useAuth } from "../../authentication/auth_provider";


//migrated
export const PanelBorderStyling = css`
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #0f0000;
`

export const LinkPanelStyling = styled(RowGrid)`
  background: white;
  border-radius: 23px;
  ${OverflowContainerVertical};
`

//migrated
const VariableListPanelStylings = css`
  background: white;
  padding: 15px;
  ${PanelBorderStyling}
  border-radius: 23px;
  border-color: white;
  ${OverflowContainerVertical}
`

//migrated
export const OuterPanel = styled(RowGrid)`
grid-column: ${props => props.grid_column};
${VariableListPanelStylings}
`
const DownloadVariablesButton = styled(PaddedBorderSlimBlack)`
  place-self: center;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  &:hover {
    background: ${ThemeColor.grey.hover};
    cursor: pointer;
  }
`

export function SelectedVariablesPanel({association, selectedVariables}) {
  const { selectionManager, forceUpdateClock } = useProposalSelectionMapping();
  const {mail, token} = useAuth();
  const graphContext = useGraph();

  const downloadCSV = () => {
    let selected_ids = selectionManager.getSelectionsForProposalAndKind(association.proposal_id, association.kind);
    let variable_nodes = selected_ids.map((variable_id) => {
      return graphContext.getNodeForId(variable_id, token, mail);
    });

    if (!isList(variable_nodes) || variable_nodes.length === 0) return;
    let exporter = new VariablesCSVExporter(variable_nodes);
    exporter.loopVariablesAddAttributesToTable(exporter.variables_attributes_by_name);
    const table_content = exporter.exportTableToCSV();
    let export_blob = new Blob([table_content], {type: 'text/csv;charset=utf-8;'});
    let url = URL.createObjectURL(export_blob);
    let down = document.createElement('a');
    down.href = url;
    down.setAttribute('download', 'variable_selection.csv');
    down.click();
  }

  return (
    <OuterPanel template_rows={"auto auto 1fr"}>
      <ColumnGrid template_columns={"1fr auto"}>
        <HeaderSmall text={"Selected Variables"} />
        <DownloadVariablesButton onClick={downloadCSV}>
            <DownloadIcon/>
        </DownloadVariablesButton>
      </ColumnGrid>
      {selectedVariables}
    </OuterPanel>
  )
}
