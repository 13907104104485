import { createContext, useContext, useState, useEffect } from "react";
import { Graph } from "typescript_helpers";
import graph from "./../../preprocessing/RacketScripts/var-manual-graph.json"
import { Node } from "typescript_helpers";
import { structuredClone } from "typescript_helpers";
import { VariableSelectionManager, CurrentNodeManager, VMHistoryManager  } from "typescript_helpers";
import { useAuth } from "../../authentication/auth_provider";

const GraphContext = createContext();
const CurrentNodeContext = createContext();
const CurrentVMLinksContext = createContext();
const VariableHistoryContext = createContext();
const ProposalSelectionMappingContext = createContext();
const CurrentNodesContext = createContext();
const VMHistoriesContext = createContext();

//migrated
export const CurrentNodesProvider = ({ children }) => {
  const [nodesManager, setNodesManager] = useState(new CurrentNodeManager());
  const [forceUpdateClock, setForceUpdateClock] = useState(true);

  const setMan = (newManager) => {
    setNodesManager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const ContextValues = {
    nodesManager: nodesManager,
    setNodesManager: setMan,
    forceUpdateClock: forceUpdateClock
  }

  return (
    <CurrentNodesContext.Provider value={ContextValues}>
      {children}
    </CurrentNodesContext.Provider>
  )
}
//migrated
export const useCurrentNodes = () => {
  return useContext(CurrentNodesContext);
}

//migrated
export const SelectionMappingProvider = ({ children }) => {
  const [selection_manager, set_selection_manager] = useState(new VariableSelectionManager());
  const [forceUpdateClock, setForceUpdateClock] = useState(true);

  const setMan = (newManager) => {
    set_selection_manager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const ContextValues = {
    selectionManager: selection_manager,
    setSelectionManager: setMan,
    forceUpdateClock: forceUpdateClock
  }


  return (
    <ProposalSelectionMappingContext.Provider value={ContextValues}>
      {children}
    </ProposalSelectionMappingContext.Provider>
  )
}

//migrated
export const useProposalSelectionMapping = () => {
  return useContext(ProposalSelectionMappingContext);
}

//migrated
export const GraphProvider = ({ children }) => {
  const {token, mail} = useAuth();
  const [referenceGraph, setReferenceGraph] = useState(
    new Graph(
      [],
      [],
      ["Root"]));
  const getNodesLinkingToID = (id) => {
    let nodes = referenceGraph.getLinkedToNodes(id);
    return nodes;
  }

  window["graph"] = referenceGraph;

  const executeNodeSearch = (searchString) => {
    let res_graph = referenceGraph.addSearchNode(searchString);
    let nodeID_search_result = `Search Term: ${searchString}`;
    res_graph = res_graph.addNewNode("meta_node: Searches", [
      {
        attributeName: "Description",
        attributeContent: "This Context links to all previously made searches.",
        visibility: "visible"
      }
    ], "visible", "context", "User Searches");
    res_graph = res_graph.addNewLink("meta_node: Searches", nodeID_search_result);
    setReferenceGraph(res_graph);
    return nodeID_search_result;
  }

  const getNodesLinkedFromID = (id) => {
    console.warn("getNodesLinkingFromID")
    let nodes = referenceGraph.getLinkedFromNodes(id);
    return nodes;
  }

  const getGraph = () => { return referenceGraph; };

  const getNodeForId = (id) => {
    console.warn("getNodeForId")
    return referenceGraph.getNodeForId(id, token, mail);
  }
  const filterForContexts = (nodes) => {
    console.warn("filterForContexts")
    let result = {};
    for (const node of nodes) {
      if (Node.isVMContext(node)) {
        result[node.id] = node;
      }
    }
    return Object.values(result);
  };

  const filterForVariables = (nodes) => {
    console.warn("filterForVariables")
    let result = {};
    for (const node of nodes) {
      if (Node.isVariable(node)) {
        result[node.id] = node;
      }
    }
    return Object.values(result);
  }

  const getVariablesPointedToByCurrentNode = (id) => {
    console.warn("getVariablesPointedToByCurrentNode id is: " + id)
    let pointedToNodes = getNodesLinkedFromID(id);
    console.warn("pointingAtNodes are " + JSON.stringify(pointedToNodes))
    return filterForVariables(pointedToNodes);
  };

  const getVariablesPointingAtCurrentNode = (id) => {
    console.warn("getVariablesPointingAtCurrentNode id is: " + id)
    let pointingAtNodes = getNodesLinkingToID(id);
    console.warn("pointingAtNodes are " + JSON.stringify(pointingAtNodes))
    return filterForVariables(pointingAtNodes);
  };

  const getContextsPointedToByCurrentNode = (id) => {
    console.warn("getContextsPointedToByCurrentNode id is: " + id)
    console.info(`graph before call is ${JSON.stringify(getGraph())}`)
    let pointedAtNodes = getNodesLinkedFromID(id);
    console.info(`graph after call is ${JSON.stringify(getGraph())}`)
    console.warn("pointed at contexts are " + JSON.stringify(pointedAtNodes));
    return filterForContexts(pointedAtNodes);
  };

  const getContextsPointingAtCurrentNode = (id) => {
    console.warn("getContextsPointingAtCurrentNode id is: " + id)
    let pointingAtNodes = getNodesLinkingToID(id);
    console.warn("pointing at contexts are " + JSON.stringify(pointingAtNodes));
    return filterForContexts(pointingAtNodes);
  };

  const getGroupsForVariable = (id) => {
    console.warn("getGroupsForVariable id is: " + id)
    return referenceGraph.db_get_groups_for_variable(id, token, mail);
  }

  const ContextValues = {
    getNodesLinkingToID: getNodesLinkingToID,
    getNodesLinkedFromID: getNodesLinkedFromID,
    getNodeForId: getNodeForId,
    getContextsPointingAtCurrentNode: getContextsPointingAtCurrentNode,
    getContextsPointedToByCurrentNode: getContextsPointedToByCurrentNode,
    getVariablesPointedToByCurrentNode: getVariablesPointedToByCurrentNode,
    getVariablesPointingAtCurrentNode: getVariablesPointingAtCurrentNode,
    executeNodeSearch: executeNodeSearch,
    getGroupsForVariable: getGroupsForVariable,
    getGraph: getGraph
  }

  return (
    <GraphContext.Provider value={ContextValues}>
      {children}
    </GraphContext.Provider>
  )
}

//migrated
export function useGraph() {
  return useContext(GraphContext);
}

export const CurrentNodeProvider = ({ children }) => {
  const graphContext = useGraph();

  const [currentID, newCurrentID] = useState("");
  const [currentDetails, newCurrentDetails] = useState([]);

  const setID = (id, token, mail) => {
    let node = graphContext.getNodeForId(id, token, mail);
    newCurrentID(node.id);
    newCurrentDetails(node.details);
  }

  const ContextValues = {
    setID: setID,
    currentID: currentID,
    currentDetails: currentDetails
  }

  return (
    <CurrentNodeContext.Provider value={ContextValues}>
      {children}
    </CurrentNodeContext.Provider>
  )
}

export function useCurrentNode() {
  return useContext(CurrentNodeContext);
}

//migrated
export const VMHistoriesProvider = ({ children }) => {
  const [forceUpdateClock, setForceUpdateClock] = useState(false);
  const [historyManager, setHistoryManager] = useState(new VMHistoryManager());
  const [skipHistoryManagerUpdate, setSkipHistoryManagerUpdate] = useState(false);
  const { nodesManager, setNodesManager, forceUpdateClock: currentNodesUpdateClock } = useCurrentNodes();

  const updateHistory = () => {
    let { proposal_id, kind, variable_id } = nodesManager.getLastTouchedElements();
    if (variable_id === "" || proposal_id === "" || kind === "") return;
    let newManager = historyManager.addToHistory(proposal_id, kind, variable_id);
    setHistoryManager(newManager);
    setForceUpdateClock(!forceUpdateClock);
  }

  const jumpBackInHistory = (proposal_id, kind, variable_id) => {
    let { revertStatus, manager } = historyManager.revertToLastIdInHistory(proposal_id, kind, variable_id);
    if (!revertStatus) return;
    let newNodesManager = nodesManager.setCurrentNode(proposal_id, kind, variable_id);
    throw new Error("debug exception");
    setHistoryManager(manager);
    setSkipHistoryManagerUpdate(true);
    setForceUpdateClock(!forceUpdateClock);
    setNodesManager(newNodesManager);
  }

  const getLastHistoryElements = (proposal_id, kind, numberOfElements) => {
    return historyManager.getLastHistoryElements(proposal_id, kind, numberOfElements);
  }

  useEffect(() => {
    if (skipHistoryManagerUpdate) {
      setSkipHistoryManagerUpdate(false);
      return;
    }
    updateHistory();
  }, [currentNodesUpdateClock]);

  const ContextValues = {
    jumpBackInHistory: jumpBackInHistory,
    getLastHistoryElements: getLastHistoryElements,
    forceUpdateClock: forceUpdateClock
  }

  return (
    <VMHistoriesContext.Provider value={ContextValues}>
      {children}
    </VMHistoriesContext.Provider>
  )
}

//migrated
export const useVMHistories = () => { return useContext(VMHistoriesContext); }

//no longer used
export const VariableHistoryProvider = ({ children }) => {
  const currentVarContext = useCurrentNode();
  const [history, setNewHistory] = useState([]);

  const handleCurrentIDChange = (id) => {
    history.push(id);
    setNewHistory(history);
  }

  const getLastHistoryElements = (numberElements) => {
    return history.slice(-numberElements);
  }

  const jumpBackInHistory = (id_to_jump_back) => {
    let history_copy = structuredClone(history);
    let last_element = null;
    while (history_copy.length !== 0 && last_element !== id_to_jump_back) {
      last_element = history_copy.pop();
    }
    if (last_element === id_to_jump_back) {
      setNewHistory(history_copy);
      currentVarContext.setID(id_to_jump_back);
    }
  }

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID);
  }, [currentVarContext])

  const ContextValues = {
    getLastHistoryElements: getLastHistoryElements,
    jumpBackTo: jumpBackInHistory,
  }

  return (
    <VariableHistoryContext.Provider value={ContextValues}>
      {children}
    </VariableHistoryContext.Provider>
  )
}

export function useVariableHistory() {
  return useContext(VariableHistoryContext);
}

export const CurrentVMLinksProvider = ({ children }) => {
  const graphContext = useGraph();
  const currentVarContext = useCurrentNode();

  const [contextLinkedFromID, newContextLinkedFromID] = useState([]);
  const [contextLinkingToID, newContextLinkingToID] = useState([]);
  const [variablesLinkedFromID, newVariablesLinkedFromID] = useState([]);
  const [variablesLinkingToID, newVariablesLinkingToID] = useState([]);

  const handleCurrentIDChange = (id) => {
    let linkedFrom = graphContext.getNodesLinkedFromID(id);
    let linkedTo = graphContext.getNodesLinkingToID(id);
    let clf = {};
    let clt = {};
    let vlf = {};
    let vlt = {};

    for (const node of linkedFrom) {
      if (Node.isVariable(node)) {
        vlf[node.id] = node;
      }
      if (Node.isVMContext(node)) {
        clf[node.id] = node;
      }
    }

    for (const node of linkedTo) {
      if (Node.isVariable(node)) {
        vlt[node.id] = node;
      }
      if (Node.isVMContext(node)) {
        clt[node.id] = node;
      }
    }

    newContextLinkedFromID(Object.values(clf));
    newContextLinkingToID(Object.values(clt));
    newVariablesLinkedFromID(Object.values(vlf));
    newVariablesLinkingToID(Object.values(vlt));
  }

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID);
  }, [currentVarContext])

  useEffect(() => {
    handleCurrentIDChange(currentVarContext.currentID)
  }, [])

  const ContextValues = {
    contextLinkedFrom: contextLinkedFromID,
    contextLinkedTo: contextLinkingToID,
    variablesLinkedFrom: variablesLinkedFromID,
    variablesLinkedTo: variablesLinkingToID
  }

  return (
    <CurrentVMLinksContext.Provider value={ContextValues}>
      {children}
    </CurrentVMLinksContext.Provider>
  )
}

export function useCurrentVMLinks() {
  return useContext(CurrentVMLinksContext);
}
