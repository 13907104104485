import { isNonNullObject } from "typescript_helpers";

//tested

//migrated
export function request_success(response) {
  return isNonNullObject(response) && response.status === 'request_success';
}

//migrated
export function get_request(body){
  if(body.jwt === null || body.jwt === undefined) throw new Error("no auth token provided");
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body)
  };
  return request;
}

//migrated
export async function fetch_json_endpoint(endpoint_url, request_body){
  try{
    const result = await fetch(endpoint_url, get_request(request_body));
    const response = await result.json();
    console.log(`response is ${JSON.stringify(response)}`)
    return response;
  } catch(error){
    const result = {
      'status': 'server_error',
      'message': error
    };
    return result;
  }
}

//migrated
export function onErrorResponse(response){
  if (!request_success(response)){
    throw new Error(`some error occured while processing request. Response is ${JSON.stringify(response)}`);
  }
}
