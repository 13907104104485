import { Outlet, Route, Routes } from "react-router-dom"
import { AccountManagement, AdminSetNewUser, AdminSetPassword, ChangePassword } from "./accountmanagement"
import { LoginForm } from "./forms/login_form"
import { NavForm, NavFormTop } from "./forms/navigation_form"
import { RecentPublications } from "./publications"
import { Playground } from "./css_playground"
import { IssueProposalFormDev } from "./proposals/proposal_form_dev"
import { Test } from "./Testing"
import { ProposalRenderer } from "./proposals/issued_proposals"
import { RequireAdmin, RequireAuth } from "../authentication/require_auth"
import { LoginPage } from "../authentication/login_page"
//import { VariableManual } from "typescript_helpers"
import { VariableManual, variablemanual_standalone_association_value } from "./variablenmanual/variablemanual"
import { NoteIntentionFromDev } from "./proposals/NoteIntentionFormDev"
import { HomePage } from "./home"

export function ContentRoutes(props){
    return (
    <Routes>
        <Route path="/" element={<Outlet />}>
            <Route index element={/*<><LoginPage /><LoginForm /></>*/<HomePage/>} />
            {ContentRouteLanding()} 
            {ContentRouteAccount()} 
            {ContentRouteStart()} 
            {ContentRoutePublications()} 
            {ContentRouteProposals()}
            {ContentRouteVariablemanual()}
            {ContentRoutePlayground()}
            {ContentRouteTesting()}
            {ContentRoutesCatchAll()}
            {ContentRouteLogin()} 
        </Route>
    </Routes>
    )
}

function ContentRoutesCatchAll() {
    return (
        <Route 
            path="*" 
            element={<div>catchall path</div>} 
        />
    )
}

function ContentRouteLogin(){
  return (
    <Route 
      path="login"
      element={<LoginPage/>}
      />
  )
}

function ContentRoutePlayground(){
  return (
    <Route path="css" element={<Playground/>}/>
  )
}

function ContentRouteTesting(){
  return (
    <Route path="test" element={<Test/>}/>
  )
}

function ContentRouteVariablemanual(){
    return (
        <Route
            path="variablemanual"
            element={
              <RequireAuth>
                <VariableManual association={variablemanual_standalone_association_value}/>  
              </RequireAuth>
              }
        />
    )
}


function ContentRouteProposals() {
    return (
    <Route
        path="proposals"
        element={<Outlet />}
    >
        <Route index element={<ProposalRenderer/>} />
        <Route
            path="issueProposal"
            element={
              <RequireAuth>
                <IssueProposalFormDev/>
              </RequireAuth>
              } />
        <Route 
          path="issueProposal_preselected_variables"
          element={
            <RequireAuth>
              <IssueProposalFormDev mode={"preselected_variables"}/>
            </RequireAuth>
          }/>
        <Route
            path="overview"
            element={
            <RequireAuth>
              <ProposalRenderer/>
            </RequireAuth>
            } />
        <Route
          path="noteIntention"
          element={
          <RequireAuth>
            <NoteIntentionFromDev />
          </RequireAuth>
        }
        />
        <Route
          path="antrag_nachtragen"
          element={
            <RequireAuth>
              <IssueProposalFormDev mode={"nachtragen"}/>
            </RequireAuth>
          } />
    </Route>
    )
}

function ContentRoutePublications() {
    return (
        <Route
            path="publications"
            element={<RecentPublications/>} 
        />
            
    )
}

function ContentRouteStart() {
    return (
        <Route
            path="start"
            element={<div>Proposals</div>} 
        />
    )
}

function ContentRouteAccount() {
    return (
        <Route
            path="account"
            element={<Outlet />} 
        >
            <Route index element={<AccountManagement/>} />
            <Route 
                path="changePassword"
                element={
                  <RequireAuth>
                    <ChangePassword/>
                  </RequireAuth>}/>
            <Route  path="setUserPassword"
                    element={
                      <RequireAdmin>
                        <AdminSetPassword />
                      </RequireAdmin>}/>
            <Route  path="addNewUser"
                    element={
                      <RequireAdmin>
                        <AdminSetNewUser />
                      </RequireAdmin>
                    }/>
        </Route>
    )
}

function ContentRouteLanding(props){
    return (
        <Route
        path="landing"
        element={<RecentPublications/>}
        />
    )
}

export function MenuRoutes(props){
    return (
    <Routes>
        <Route path="/" element={<Outlet />}>
            <Route index element={<NavFormTop/>}/>
            {MenuRoutesLanding()} 
            {MenuRoutesAccount()} 
            {MenuRoutesStart()} 
            {MenuRoutesProposals()} 
            {MenuRoutesPublications()} 
            {MenuRoutesVariablenmanual()} 
            {MenuRoutesCatchAll()} 
            {MenuRouteLogin()}
    </Route>
</Routes>
    )
}

function MenuRoutesCatchAll() {
    return (
        <Route 
            path="*" 
            element={<div>catchall path</div>} 
        />
    )
}

function MenuRoutesVariablenmanual() {
    return (
        <Route 
            path="variablemanual" 
            element={<NavFormTop/>}
        >
            <Route 
                path="*" 
                element={<NavFormTop/>} 
            />
        </Route>
    )
}

function MenuRoutesPublications() {
    return (
        <Route 
            path="publications" 
            element={<NavFormTop/>}
        >
            <Route 
                path="*" 
                element={<NavFormTop/>} 
            />
        </Route>
    )
}

function MenuRoutesProposals() {
    return (
        <Route 
            path="proposals" 
            element={<NavFormTop />}
        >
            <Route 
                path="*" 
                element={<NavFormTop />} 
            />
        </Route>
    )
}

function MenuRoutesStart() {
    return (
        <Route
            path="start"
            element={<NavFormTop/>} 
        />
    )
}

function MenuRoutesAccount() {
    return (
        <Route 
            path="account" 
            element={<NavFormTop/>}
        >
            <Route 
                path="*" 
                element={<NavFormTop/>} 
            />
        </Route>
    )
}

function MenuRoutesLanding() {
    return (
        <Route 
            path="landing"
            element={<NavFormTop/>} 
        />
    )
}

function MenuRouteLogin(){
  return (
    <Route
      path="login"
      element={<NavFormTop/>}
      />
  )
}
