import styled from "styled-components";
import { CollapseMenuArea } from "../buttons/menu_area_buttons";
import { MenuAreaFormBackground } from "./form_backgrounds";

import {ReactComponent as Logo} from "../../img/hchs_logo.svg";
export const MenuAreaFormWrapper = styled.div`
    background: linear-gradient(90deg, #f00, #fff);
    position: relative;
    height: 600px;
    width: 360px;
    box-shadow: 0px 0px 20px 15px rgba(255, 255, 255, 0.8);
    border-radius: 0px;
    overflow: hidden;
`;

const TopRightWidgetContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    height: 30px;
    width: 30px;
    z-index: 1;
`;

const InputPositioningTopDown = styled.form`
    padding: 30px;
    padding-top: 83px;
    position: relative;
    z-index: 1;
    height: 100%;
`;

const InputPositioningThreeElementsMiddle = styled.form`
    padding: 30px;
    padding-top: 156px;
    z-index: 1;
    position: relative;
    height: 100%;
`;

const FloatLeft = styled.div`
`

export function HeaderInputsWithPositioning(props){
  return <div style={{
    "flex-grow": "1",
    "display": "flex",
    "justify-content": "flex-start",
    "align-items": "center",
    "background-color": "white",
    "height": "100%",
    "margin": "0px 2rem",
    "margin-top": "1rem"
  }}>
    <div position={"relative"} height="50%">

      <Logo margin="15px" height="100%" width="100%"/></div>
    <div style={{
      "display": "flex",
      "gap": "20px",
      "justify-content": "space-around",
      "flex-grow": "1"
    }
    }>
      {props.children}
    </div>
  </div>
}

export function FormInputsWithPositioning(props){
    switch (props.InputPositioning){
        case "login":
            return (
            <InputPositioningThreeElementsMiddle action={props.FormAction}>
                {props.children}
            </InputPositioningThreeElementsMiddle>
            )
        default:
            return (
                <InputPositioningTopDown>
                    {props.children}
                </InputPositioningTopDown>
            )
    }
}

export function BasicMenuAreaFormStyling(props){
    return (
        <MenuAreaFormWrapper>
            {props.children}
            <MenuAreaFormBackground/>
            <TopRightWidgetContainer>
                <CollapseMenuArea/>
            </TopRightWidgetContainer>
        </MenuAreaFormWrapper>
    )
}
